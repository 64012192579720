/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"

import LinkedinIcon from "../images/linkedin.png"
import behance from "../images/behance.png"
import dribbble from "../images/dribbble.png"
import EmailIcon from "../images/email.png"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <footer
          className="footer container"
          style={{
            marginTop: `2rem`,
            marginBottom: `2rem`,
            textAlign: "center",
          }}
        >
          <div className="social-container">
            <h1>Let's Connect</h1>
            <p>Get in touch for the oppertunities</p>

            <div className="social-img-wrapper">
              <Link
                target="_blank"
                to="https://www.linkedin.com/in/ismhamza/"
                rel="noreferrer"
              >
                <img width={26} src={LinkedinIcon} alt="linkedin" />
              </Link>
              <a href="mailto:hamza_dsl@hotmail.com">
                <img width={26} className="ml-3" src={EmailIcon} alt="email" />
              </a>
              <Link
                className="ml-3"
                target="_blank"
                to="https://www.behance.net/smhamzame"
                rel="noreferrer"
              >
                <img width={26} src={behance} alt="behance" />
              </Link>
              <Link
                className="ml-3"
                target="_blank"
                to="https://dribbble.com/iamhamza"
                rel="noreferrer"
              >
                <img width={26} src={dribbble} alt="dribbble" />
              </Link>
            </div>
          </div>
          <div className="copyright">
            © {new Date().getFullYear()}, Built with{" "}
            <span style={{ color: "green" }}>&#10084;</span> in
            {` `}
            <a
              target="_blank"
              href="https://en.wikipedia.org/wiki/Pakistan"
              rel="noreferrer"
            >
              Pakistan
            </a>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
