import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import CV from "../images/SyedHamzaResume.pdf"
import behance from "../images/behance.png"
import dribbble from "../images/dribbble.png"
import Logo from "../images/logo.png"

const Header = ({ siteTitle }) => (
  <header className="navbar" id="header">
    <h1 className="logo-w mt-sm-2">
      <Link to="/" className="d-flex">
        {/* {siteTitle} */}
        <img className="m-0" width={60} src={Logo} alt="Logo" />
      </Link>
    </h1>
    <div className="nav-container d-flex align-items-center">
      <Link to="/#work" className="nav-link active">
        Work
      </Link>
      <a href={CV} target="_blank" className="nav-link" rel="noreferrer">
        Resume
      </a>
      <div className="social-wrapper d-flex">
        <a
          href={"https://www.behance.net/smhamzame"}
          target="_blank"
          className="icon-button-rounded d-flex align-items-center"
          rel="noreferrer"
        >
          <img
            className="m-0 social-button"
            width={100}
            src={behance}
            alt="behance"
          />
        </a>
        <a
          href={"https://dribbble.com/iamhamza"}
          target="_blank"
          className="icon-button-rounded d-flex align-items-center ml-1"
          rel="noreferrer"
        >
          <img
            className="m-0 social-button"
            width={100}
            src={dribbble}
            alt="dribbble"
          />
        </a>
        <a
          href="mailto:smhamzame@gmail.com"
          target="_blank"
          className="btn-outline d-flex align-items-center ml-2 d-sm-none"
          rel="noreferrer"
        >
          {/* <img
            className="m-0 social-button"
            width={20}
            src={email}
            alt="email"
          /> */}
          Let's Talk
        </a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
